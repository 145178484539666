import React, { useRef } from 'react'
import styled from 'styled-components'
import { rem, flex, grid } from 'styled-tidy'
import media from 'lib/styles/media'
import Project from 'components/project'
import Badges from 'components/badges'
import CodeBlock from 'components/code-block'
import { jsx, styles } from 'lib/code-blocks/chop-lines'

import exampleClick from 'gifs/chop-lines-click.gif'
import exampleResponsive from 'gifs/chop-lines-responsive.gif'

const GifGrid = styled.p`
  ${grid(1, 24)}

  img {
    max-width: 100%;
  }

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const Usage = styled.div`
  table {
    margin: ${rem(24)} 0;
    width: 100%;
  }

  ${media.medium`
    ${flex('row', 'flex-start', 'space-between')}
    margin-bottom: ${rem(24)};

    > div {
      flex-grow: 1;
      margin-right: ${rem(32)};
    }

    table {
      margin: 0;
      width: auto;
    }
  `}
`

export default () => {
  const usageBookmarkRef = useRef()
  const implementationBookmarkRef = useRef()
  const subnav = [
    { to: '/', name: '←', label: 'Back' },
    {
      id: 'usabe',
      ref: usageBookmarkRef,
      name: 'Usage',
    },
    {
      id: 'implementation',
      ref: implementationBookmarkRef,
      name: 'Implementation',
    },
  ]

  return (
    <Project name="ChopLines" subnav={subnav}>
      <div>
        <p>
          ChopLines is a lightweight React component which truncates multiple lines of HTML markup.
        </p>
        <Badges>
          <img
            src="https://camo.githubusercontent.com/5218b1f15c44a7d50f4e9deb5da8bd989d4d2387/68747470733a2f2f62616467656e2e6e65742f6769746875622f72656c656173652f6477322f63686f702d6c696e6573"
            alt="Release Version"
          />
          <img
            src="https://camo.githubusercontent.com/fb598478bc6df07e211731378d2d28d884d39b74/68747470733a2f2f62616467656e2e6e65742f626164676573697a652f677a69702f68747470733a2f2f756e706b672e636f6d2f63686f702d6c696e6573"
            alt="Gzip Size"
          />
          <img
            src="https://camo.githubusercontent.com/4ff2eab0ddf16d573ffa7f27d6c0b1024c6ba4e3/68747470733a2f2f636f6465636f762e696f2f67682f6477322f63686f702d6c696e65732f6272616e63682f6d61737465722f67726170682f62616467652e737667"
            alt="Test Coverage"
          />
        </Badges>
        <p>
          I found several React components which truncate plain text, but few that supported truncation of HTML, and none I was happy with. ChopLines solves the challenge by measuring its children and applying truncation for a given height or number of lines.
        </p>
      </div>
      <div>
        <h2>See it in action</h2>
        <GifGrid>
          <img src={exampleClick} alt="" />
          <img src={exampleResponsive} alt="" />
        </GifGrid>
        <div id="usage" ref={usageBookmarkRef} />
        <h2>Usage</h2>
        <Usage>
          <div>
            <p>Add the dependency:</p>
            <CodeBlock
              lang="markup"
              code="yarn add chop-lines"
            />
            <p>And then in your component(s):</p>
            <CodeBlock
              lang="javascript"
              code="import ChopLines from 'chop-lines';"
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>Prop</th>
                <th>Requirement</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>lines</th>
                <td>required if maxHeight is not set</td>
              </tr>
              <tr>
                <th>lineHeight</th>
                <td>required if maxHeight is not set</td>
              </tr>
              <tr>
                <th>maxHeight</th>
                <td>required if lines &amp; linesHeight are not set</td>
              </tr>
              <tr>
                <th>ellipsis</th>
                <td>Optional (default: "…")</td>
              </tr>
              <tr>
                <th>children</th>
                <td>required</td>
              </tr>
            </tbody>
          </table>
        </Usage>
        <div id="implementation" ref={implementationBookmarkRef} />
        <h2>Implementation Example</h2>
        <CodeBlock label="example.jsx" lang="javascript" code={jsx} />
        <CodeBlock label="styles.js" lang="javascript" code={styles} />
      </div>
    </Project>
  )
}
